import React from "react";

// Customizable Area Start
import { Box, Button, Modal, Typography } from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { view,download } from "./assets";
import { PieChart, Pie, Cell, Tooltip,AreaChart,Area, XAxis, YAxis, Label } from 'recharts';
import MonthlyPopup from "../../../components/src/MonthlyPopup.web";
import { PlusIcon, plusButton, drop, calendar } from "../../../blocks/analytics/src/assets";
import Calendar from "react-calendar";
import CloseIcon from "@material-ui/icons/Close"

const ZeroCheck = () => {
    return (
      <Typography style={{
        color: "#64748B",
        fontSize: "16px",
        fontFamily: "DIN Next LT Arabic Regular",
        textAlign: "center",
        padding: "16px",
      }}>
        No data found
      </Typography>
    );
};
// Customizable Area End

import VisualAnalyticsReportController, {
  Props,
} from "./VisualAnalyticsReportController.web";

export default class Analytics extends VisualAnalyticsReportController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <StyledTooltip>
          <p className="tooltip-text">
            {payload[0].payload?.amountString}
          </p>
          <div>
            <div className="tooltip-date">
              {payload[0].payload?.date + " " + payload[0].payload?.time}
            </div>
          </div>
        </StyledTooltip>
      );
    }
    return null;
  };

  renderFullScreenAreaChart = () => {
    return (
      <Modal
      open={this.state.isFullScreen}
      onClose={this.handleFullscreenToggle}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box style={{ width: '90vw', height: '90vh', backgroundColor: 'white', padding: '20px' }}>
        <Typography variant="h6" onClick={this.handleFullscreenToggle} style={{ cursor: 'pointer', textAlign: 'right' }}>
          <CloseIcon />
        </Typography>
        <AreaChart
          width={window.innerWidth * 0.8}
          height={window.innerHeight * 0.8}
          data={this.state.monthlyReportGraph}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="date" style={{ fontSize: "18px", color: "#94A3B8", fontFamily: "DIN Next LT Arabic Regular" }} />
          <YAxis />
          <Tooltip content={<this.CustomTooltip tooltipData={this.state.monthlyReportGraph} />} />
          <Area type="monotone" dataKey="amount" strokeWidth={2} stroke="#1B4FE4" fill="url(#colorUv)" />
        </AreaChart>
      </Box>
    </Modal>
    )
  }

  // Customizable Area End

  render() {
    console.log("Monthly chart", this.state.monthlyAnalyticsChart)
    return (
      // Customizable Area Start
      <div style={{ display: "flex", width: "fit-content" }}>
        <Box>
          <NavigationMenu id="" navigation={this.props.navigation}></NavigationMenu>
        </Box>
      <StyledWrapper>
        <Box className="mainContainer" >
          <Box className="reportContainer" >
            <MonthlyPopup
              open={Boolean(this.state.anchorElMonthly)}
              anchorEl={this.state.anchorElMonthly}
              valueList={this.state.monthlyListing}
              selectedValue={this.state.selectedMonth}
              onClose={this.handleMonthlyPopoverClose}
              onSelectValue={this.handleMonthSelection}
            />
            <MonthlyPopup
              open={Boolean(this.state.anchorElYearly)}
              anchorEl={this.state.anchorElYearly}
              valueList={this.state.yearlyListing}
              selectedValue={this.state.selectedYear}
              onClose={this.handleYearlyPopoverClose}
              onSelectValue={this.handleYearSelection}
            />
            {this.renderFullScreenAreaChart()}
            <Box className="dashboardBox">
              <Box className="dashBox">
                <Typography className="dashboardStyle">
                  Monthly Report
                </Typography>
              </Box>
              <Box className="dashboardBox2">
              
                <img src={plusButton} className="ButtonPlus" onClick={this.handleButtonBox} style={{position: "relative"}}/>
                {this.handleCondition(this.state.buttonBox, (
                          <Box
                            className="profitContainerButton"
                            style={{ position: "absolute" }}
                          >
                            <Box className="popupBox2">
                            <Button
                              data-test-id="profitList0"
                              key="0"
                              className={`profitData ${
                                this.handleCondition(this.state.activeTab === 0, 'active', 'profitData')
                              }`}
                              onClick={this.withdrawPage}
                              
                            >
                              New Withdraw Request
                            </Button>

                            <Button
                              data-test-id="profitList1"
                              key="1"
                              className={`profitData ${
                                this.handleCondition(this.state.activeTab === 1, 'active', 'profitData')
                              }`}
                              onClick={this.depositPage}
                            >
                              New Deposit Request
                            </Button>
                            </Box>
                          </Box>
                        ), "")}
                <Box className="requestButton" onClick={this.withdrawPage}>
                  <img src={PlusIcon} className="plusStyle" />
                  <button className="buttonOne">New Withdraw Request</button>
                </Box>
                <Box className="requestButton" onClick={this.depositPage}>
                  <img src={PlusIcon} className="plusStyle" />
                  <button className="buttonOne">New Deposit Request</button>
                </Box>
              </Box>
            </Box>
            <Box className="mobilePositionMonthyDropDown">
            <Box className="dropdownBox">
            <Typography onClick={this.handleMonthlyPopoverOpen} className="weekHeading" >This Month</Typography>
            <img src={drop} alt="" style={{cursor: 'pointer'}}/>
            </Box>
            </Box>
              <Box className="monthlyAnalyticWrapper" >
                <Box className="analyticContainer" >
                  <Box className="monthlyAnalytic" >
                    <Typography className="heading" >Monthly Analytics</Typography>
                    <Box className="analyticDetail" >
                      <Box className="pieChartContainer">
                        <PieChart width={100} height={100}>
                          <Pie
                            data={this.state.monthlyAnalyticsChart}
                            cx={43}
                            cy={43}
                            innerRadius={30}
                            outerRadius={40}
                            fill="#8884d8"
                            paddingAngle={0}
                            dataKey="value"
                          >
                            <Label
                              value={2}
                              position="center"
                              fill="#000"
                              style={{ fontSize: '20px', color: "#1B4FE4", fontFamily: "DIN Next LT Arabic Regular" }}
                            />
                          </Pie>
                        </PieChart>
                      </Box>
                      <Box width={'100%'}>
                        <Box className="analyticDetails">
                          <Box className="profitContainer" >
                            <Typography className="profitFigure" >{this.state.monthlyAnalytics.cumulative_profit}</Typography>
                            <Typography className="analyticText" >Cumulative Monthly Profit</Typography>
                          </Box>
                          <Box className="bulletSign"  ></Box>
                        </Box>
                        <Box className="analyticDetails" >
                          <Box className="profitContainer" >
                            <Typography className="monthFigure" >{this.state.monthlyAnalytics.change_from_last_month}</Typography>
                            <Typography className="analyticText" >Change from Last Month</Typography>
                          </Box>
                          <Box className="bulletSign" style={{ backgroundColor: "#EF4444" }}  ></Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="analyticContainerYY" >
                  <Box className="monthlyAnalytic" >
                    <Box className="weekContainer" >
                      <Typography className="analyticHeading" >Analytics</Typography>
                      <Box className="changePosition">
                        <Box className="dropdownBox">
                          <Typography onClick={this.handleMonthlyPopoverOpen} className="weekHeading" >This Month</Typography>
                          <img src={drop} alt="" style={{ cursor: 'pointer' }} />
                        </Box>
                      </Box>
                    </Box>
                    <Box className="analyticDetail" >
                      <Box className="pieChartContainer">
                        <PieChart width={100} height={100}>
                          <Pie
                            data={this.state.data}
                            cx={43}
                            cy={43}
                            innerRadius={30}
                            outerRadius={40}
                            fill="#F59E0B"
                            paddingAngle={0}
                            dataKey="value"
                          >
                            <Label
                              value={1}
                              position="center"
                              fill="#000"
                              style={{ fontSize: '20px', color: "#1B4FE4", fontFamily: "DIN Next LT Arabic Regular" }}
                            />
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </Box>
                      <Box width={'100%'}>
                        <Box className="analyticDetails" style={{ marginBottom: "8px" }} >
                          <Box className="profitContainer" >
                            <Typography className="monthFigure" >91%</Typography>
                            <Typography className="analyticText" >Rate of Succesful Trades</Typography>
                          </Box>
                          <Box className="bulletSign" style={{ backgroundColor: "#F59E0B" }}  ></Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            <Box className="yearProfitContainer" >
              <Box className="headingContainer" >
                <Typography className="dateHeading">Year to date profit</Typography>
                  <Box className="dropdownBox">
                    <Typography onClick={this.handleYearlyPopoverOpen} className="weekHeading" >This Year</Typography>
                    <img src={drop} alt="" style={{cursor: 'pointer'}} />
                  </Box>
              </Box>
              <Box className="yearToProfitCenter">
                <Box>
                  <Typography className="profitFigure" style={{ fontSize: "14px", color: "#64748B" }}>{this.state.yearToDateProfit}</Typography>
                </Box>
              </Box>
            </Box>
            <Box className="topTradeContainer" >
              <Box className="headingContainer" >
                <Typography className="dateHeading">Top Traded Industries</Typography>
                <Typography className="weekHeading" >This Week</Typography>
              </Box>
              <Box className="tradeContainer" >
                <Box className="pieChartContainer" >
                  <PieChart width={100} height={100}>
                    <Pie
                      data={
                        this.handleCondition(this.state.topTradeIndustries?.data.length > 0
                          , this.state.topTradeChart
                          , [{ name: "No Data", value: 1, fill: "#94A3B8" }])
                      }
                      cx={43}
                      cy={43}
                      innerRadius={30}
                      outerRadius={40}
                      fill="#8884d8"
                      paddingAngle={0}
                      dataKey="value"
                    >
                      <Label
                        value={this.state.topTradeIndustries.data.length}
                        position="center"
                        fill="#000"
                        style={{ fontSize: '16px', color: "#1B4FE4", fontFamily: "DIN Next LT Arabic Regular" }}
                      />
                    </Pie>
                  </PieChart>
                </Box>
                <Box className="tradeDetails" >
                  {
                    this.handleCondition(this.state.topTradeIndustries.data.length === 0,
                      ZeroCheck(),
                      this.state.topTradeIndustries.data.map((item, index) => {
                        return (
                          <Box className="dateProfitDetail">
                            <Box style={{ display: "flex", gap: "16px" }} >
                              <Typography className="profitFigure" style={{ color: this.getColorByIndex(index) }} >{item.trade_percentage}</Typography>
                              <Typography className="analyticText" >{item.trade_name}</Typography>
                            </Box>
                            <Box>
                              <Box className="bulletSign" style={{ backgroundColor: this.getColorByIndex(index) }}  ></Box>
                            </Box>
                          </Box>
                        )
                      })
                    )
                  }
                </Box>
              </Box>
            </Box>
            <Box className="downloadSection">
              <Box className="figures" style={{ backgroundColor: "#10B981" }} >
                <Typography className="figureText" >65%</Typography>
              </Box>
              <Box className="figures" style={{ backgroundColor: "#EF4444" }} >
                <Typography className="figureText" >35%</Typography>
              </Box>
              <Box onClick={this.handleFullscreenToggle} className="openGraph downloadText" >
                <img src={view} style={{cursor: 'pointer'}} />
              </Box>
              <Box className="downloadBtn" onClick={this.getDownloadGraphRequest} > <img src={download} /> <Typography className="calendar downloadText">Download Report</Typography></Box>
              <Box>
                  <Box className="activityrightttext"
                    style={{ position: "relative" }}>
                    <Typography className="calendar downloadText" onClick={this.openCalendar}>Calendar</Typography>
                    <img
                      src={calendar}
                      alt=""
                      onClick={this.openCalendar}
                    />
                    <CustomDatePicker>
                      {this.state.calendarOpen && (
                        <div className="reactCalendarBox" style={{ position: "absolute" }}>
                          <div className="calBox">
                            <Calendar
                              selectRange
                              onChange={this.handleDateChange}
                              data-testID="calendar"
                              value={this.state.tempDate}
                            />
                            <div className="action-button-div">
                              <Button
                                className="action-btn cancel-btn"
                                onClick={this.handleCalendarCancelBtn}
                                data-testID="cancel-btn"
                              >
                                Cancel
                              </Button>
                              <Button
                                data-testID="save-btn"
                                className={this.handleCondition(this.state.tempDate, `action-btn save-btn active`, `action-btn save-btn`)}
                                onClick={this.handleCalendarSaveBtn}
                              >
                                Apply
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                    </CustomDatePicker>
                  </Box>
              </Box>
            </Box>
            <Box className="reportsContainer">
              <Box className="graphContainer">
                <div style={{ minWidth: '1000px' }}>
                  <AreaChart
                    className="chartDashboard"
                    width={1400}
                    height={400}
                    data={this.state.monthlyReportGraph}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                  >
                    <defs>
                      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                    <XAxis
                      dataKey="date"
                      style={{ fontSize: "18px", color: "#94A3B8", fontFamily: "DIN Next LT Arabic Regular" }}
                      interval={0}
                      tickCount={5}
                      padding={{ right: 20 }}
                    />
                    <YAxis />
                    <Tooltip content={<this.CustomTooltip tooltipData={this.state.monthlyReportGraph} />} />
                    <Area type="monotone" style={{ marginBottom: '30px' }} dataKey="amount" strokeWidth={2} stroke="#1B4FE4" fill="url(#colorUv)" />
                  </AreaChart>
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      </StyledWrapper>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    }
  }
});

const StyledWrapper = styled(Box)({
  height:"100%",
  zIndex: -1,
    position: "absolute",
    width: "calc(100vw - 358px)",
    [theme.breakpoints.up(900)]: {
      zIndex: 0,
      position: "inherit",
    },
  "& .mainContainer": {
    display:'flex',
  },
  "& .dashboardBox": {
    [theme.breakpoints.down(900)]: {
      width: "calc(100vw - 75px)",
      paddingTop:"4.5px"
    },
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    padding: "0 20px 0 40px",
    width: "calc(100% - 80px)",
    minWidth: "calc(100% - 80px)",
  },
  "& .dashBox": {
    [theme.breakpoints.down(900)]: {},
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    paddingBottom: "10px",
    with: "30px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
  },
  "& .dashboardStyle": {
    textAlign: "center",
    color: "#334155",
    fontSize: "30px",
    fontFamily: "DIN Next LT Arabic Bold",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .profitContainerButton": {
    [theme.breakpoints.up(750)]: {
      display: "none"
    },
    top: "7%",
    zIndex: 30,
    width: "250px",
  },
  "& .popupBox2": {
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 4px 0px #00000026",
    marginTop:"10px"
  },
  "& .profitData": {
    fontSize: "16px",
    fontFamily: "DIN Next LT Arabic Light",
    padding: "12px 16px",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "#3C3E49",
    cursor: 'pointer'
  },
  "& .active": {
    backgroundColor: "#1B4FE4",
    color: "#F8FAFC",
  },
  "& .mobilePositionMonthyDropDown": {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'flex', 
      justifyContent: 'flex-end', 
      padding: '40px 28px 0 0',
    }
  },
  "& .changePosition": {
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  "& .dropdownBox": {
    display: 'flex',
    justifyContent: 'center',
    gap: '8px',
    alignItems: 'center'
  },
  "& .dashboardBox2": {
    [theme.breakpoints.down(380)]: {
      gap: "10px",
    },
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "20px",
    width: "fit-content",
  },
  "& .serachBox": {
    [theme.breakpoints.down(900)]: {
      width: "calc(30% - 16px)",
    },
    [theme.breakpoints.down(750)]: {
      display: "none",
    },
    border: "1px solid #64748B",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: "10px 8px",
    borderRadius: "8px",
    gap: "8px",
    width: "calc(30% - 16px)",
  },
  "& .searchStyle": {
    width: "24px",
    height: "32px",
  },
  "& .dashboardSearch": {
    fontSize: "16px",
    border: "none",
    color: "#94A3B8",
    fontFamily: "DIN Next LT Arabic Regular",
    width: "100%"
  },
  "& .mobileSearch": {
    [theme.breakpoints.down(750)]: {
      width: "40px",
      height: "40px",
    },
    [theme.breakpoints.up(750)]: {
      display: "none",
    },
  },
  "& .ButtonPlus": {
    [theme.breakpoints.down(750)]: {
      width: "40px",
      height: "40px",
    },
    [theme.breakpoints.up(750)]: {
      display: "none",
    },
    cursor: 'pointer'
  },
  "& .requestButton": {
    [theme.breakpoints.down(900)]: {
      padding: "14px 8px",
    },
    [theme.breakpoints.down(750)]: {
      display: "none",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFFFFF",
    backgroundColor: "#1B4FE4",
    gap: "8px",
    padding: "12px 16px",
    borderRadius: "8px",
    flexWrap: "nowrap",
    cursor:"pointer"
  },
  "& .plusStyle": {
    [theme.breakpoints.down(1276)]: { width: "23px", height: "23px" },
    [theme.breakpoints.down(1217)]: { width: "20px", height: "20px" },
    [theme.breakpoints.down(1120)]: { width: "17px", height: "17px" },
    [theme.breakpoints.down(1021)]: { width: "15px", height: "15px" },
    [theme.breakpoints.down(900)]: {},
    cursor:"pointer"
  },
  "& .buttonOne": {
    [theme.breakpoints.down(1451)]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down(1370)]: {
      fontSize: "13px",
    },
    [theme.breakpoints.down(1262)]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down(900)]: {
      fontSize: "12px",
    },
    color: "#FFFFFF",
    fontWeight: 700,
    backgroundColor: "#1B4FE4",
    fontSize: "16px",
    border: "none",
    fontFamily: "DIN Next LT Arabic Bold",
    flexWrap: "nowrap",
    cursor:"pointer"
  },
  "& .monthlyAnalyticWrapper": {
    display:"flex", 
    justifyContent:"space-between",
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      flexDirection: 'column-reverse',
    }
  },
  "& .reportContainer": {
    [theme.breakpoints.down(900)]: {
      position: "absolute",
      top: "0",
      padding: "0",
      // zIndex: 99,
      width: "calc(100vw - 0px)",
      height:"calc(100vh - 1px)",
    },
    width: "calc(100vw - 358px)",
    height:"calc(100vh - 4.5rem)",
    padding: "4rem 10px 10px 0rem",
    overflow:"scroll",
    scrollbarWidth: "none", // Firefox
    msOverflowStyle: "none", // IE 10+
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent", // Chrome/Safari/Webkit
      display: "none"
    },
  },
  "& .analyticContainer":{
    marginTop:"12px",
    padding:"48px 40px 48px 40px",
    height:"246px",
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: "86%",
    },
    [theme.breakpoints.down('xs')]: {
      width: "calc(100% - 70px)",
    }
  },
  "& .analyticContainerYY":{
    marginTop:"12px",
    width: '50%',
    padding:"48px 40px 48px 40px",
    [theme.breakpoints.down('sm')]: {
      width: "86%",
    },
    [theme.breakpoints.down('xs')]: {
      width: "calc(100% - 70px)",
    }
  },
  "& .heading":{
    fontSize:"18px",
    fontWeight:"400",
    color:"#1E293B",
    textAlign:"center",
    fontFamily: "DIN Next LT Arabic Regular",
  },
  "& .analyticHeading":{
    fontSize:"18px",
    fontWeight:"400",
    color:"#1E293B",
    fontFamily: "DIN Next LT Arabic Regular",
  },
  "& .weekHeading":{
    fontSize:"16px",
    fontWeight:"400",
    color:"#1B4FE4",
    cursor: 'pointer',
    fontFamily: "DIN Next LT Arabic Regular",
  },
  "& .monthlyProfit":{
    borderRadius:"8px",
    border:"1px solid #CBD5E1",
    padding:"8px 12px 8px 12px",
    marginTop:"10px",
    height:"40px",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      justifyContent: 'center',
      gap: '20px'
    }
  },
  "& .analyticText": {
    fontSize: "16px",
    fontWeight: "400",
    color: "#64748B",
    fontFamily: "DIN Next LT Arabic Regular",
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100px',
      height: '20px',
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      display: "block"
    }
  },
  "& .calendar": {
    [theme.breakpoints.down(480)]: {},
    fontWeight: 700,
    fontSize: "16px",
    color: "#1B4FE4",
    whiteSpace: "nowrap",
    fontFamily: "DIN Next LT Arabic Bold",
    cursor: 'pointer'
  },
  "   & .activityrightttext": {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    fontSize: "18px",
    color: " #1B4FE4",
    fontWeight: 700,
    fontFamily: "DIN Next LT Arabic Bold",
    gap: "8px",
    cursor:"pointer"
  },
  "& .profitFigure":{
    color:"#10B981",
    fontSize:"16px",
    fontWeight:"400"
  },
  "& .monthFigure":{
    fontSize:"16px",
    fontWeight:"400",
    color:"#EF4444",
    fontFamily: "DIN Next LT Arabic Regular",
    flexShrink: 0
  },
  "& .bulletSign":{
    width:"10px",
    height:"10px",
    borderRadius:"100%",
    backgroundColor:"#10B981",
    flexShrink: 0
  },
  "& .profitContainer":{
    display:"flex",
    gap:"15px"
  },
  "& .analyticDetail":{
     marginTop:"22px",
     display:"flex",
     gap: '16px',
     alignItems:"center",
     [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    },
     [theme.breakpoints.down('xs')]: {
      width: '100%',
      gap: '8px',
    }
  },
  "& .pieChartContainer":{
    width:"80px",
    height:"80px",
    display:"flex",
    alignItems:"center",
    background: "#F1F5F9",
    borderRadius: '50%',
    flexShrink: 0,
  },
  "& .weekContainer":{
    display:"flex",
    justifyContent:"space-between",
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },
  "& .yearProfitContainer":{
    height:"186px",
    padding:"0px 40px 10px 40px"
  },
  "& .topTradeContainer":{
    padding:"0px 40px 10px 40px"
  },
  "& .headingContainer":{
    height:"26px",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    marginBottom: '18px'
  },
   "& .dateHeading":{
    fontSize:"18px",
    fontWeight:"400",
    color:"#1E293B",
    fontFamily: "DIN Next LT Arabic Regular",
   },
   "& .dateProfitDetail":{
    display:"flex",
    justifyContent:"space-between",
    borderRadius:"8px",
    border:"1px solid #CBD5E1",
    height:"40px",
    alignItems:"center",
    padding:"8px 12px 8px 12px",
   },
   "& .analyticDetails":{
    display:"flex",
    justifyContent:"space-between",
    borderRadius:"8px",
    border:"1px solid #CBD5E1",
    height:"40px",
    alignItems:"center",
    padding:"8px 12px 8px 12px",
    marginTop:"18px",
    [theme.breakpoints.up('sm')]: {
      minWidth: '200px',
      width: 'calc(100% - auto)'
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '400px'
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '185px'
    },

   },
   "& .yearToProfitCenter":{
    display:"flex",
    justifyContent:"center",
    borderRadius:"8px",
    border:"1px solid #CBD5E1",
    height:"40px",
    alignItems:"center",
    padding:"8px 12px 8px 12px",
    marginTop:"18px"
   },
   "& .tradeDetails":{
    width:"100%",
    display: 'flex',
    flexDirection: 'column',
    gap: '18px',
    [theme.breakpoints.down('xs')]: {
      gap: '15px',
    },
   },
   "& .tradeContainer":{
    display:"flex",
    alignItems:"center",
    gap:"16px",
   },
   "& .downloadContainer":{
    padding:"48px 40px 48px 40px",
   },
   "& .reportsContainer": {
    [theme.breakpoints.down(900)]: {
      width: "calc(100vw - 75px)",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "calc(100% - 80px)",
    padding: "48px 40px",
    gap: "48px",
    flexDirection: "column",
  },
   "& .graphContainer":{ 
    width: '100%',
    overflowX: 'auto',
    marginBottom: '50px',
    "& .graphContainer:hover": {
      overflowX: "auto"
    }
  },
   "& .downloadSection":{
    height:"48px",
    display:"flex",
    alignItems:"center",
    gap:"48px",
    float:"right",
    padding:"20px 40px 20px 40px",
    [theme.breakpoints.down('xs')]: {
      gap: '20px',
      padding: "20px"
    }
   },
   "& .downloadText": {
    display: "block",
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
   },
   "& .figures":{
     width:"60px",
     borderRadius:"8px",
     padding:"12px 16px 12px 16px",
     [theme.breakpoints.down('xs')]: {
      width:"30px",
    }
   },
   "& .figureText":{
    textAlign:"center",
    color:"#FFFFFF",
    fontWeight:"400",
    fontSize:"16px"
   },
   "& .downloadBtn":{
    color:"#1B4FE4",
    display:"flex",
    gap:"8px",
    cursor: 'pointer'
   }
})

const CustomDatePicker = styled(Box)({
  borderRadius: '8px',
  position: 'relative' as 'relative',
  boxSizing: "border-box",
  "& .MuiInput-underline::after, .MuiInput-underline::before": {
    display: 'none'
  },
  "& .reactCalendarBox":{
    width: "100%",
    top: 25,
    zIndex: 30,
    display: "flex",
    justifyContent: "center" as "center",
    flexDirection:"column" as "column",
    alignItems: "flex-end",
    right: 0,
    boxShadow: "0px 2px 4px 0px #00000026",
  },
  "& .react-calendar": {
    [theme.breakpoints.down(450)]: {
      width:"275px"
    },
    width:"330px",
    border: "none",
    fontFamily: "DIN Next LT Arabic Regular",
  },
  "& .react-calendar__navigation button.react-calendar__navigation__label": {
    fontSize: '14px',
    fontFamily: "DIN Next LT Arabic Regular",
    fontWeight: 700,
    color: "#0F172A",
  },
  "& .react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button":{
    display: "none",
  },
  "& .react-calendar__navigation button": {
    fontSize: '14px',
    fontFamily: "DIN Next LT Arabic Regular",
    fontWeight: 700,
    color: "#94A3B8", 
  },
  "& .react-calendar__tile.react-calendar__tile--now": {
    background: 'none',
    color: '#0F172A',
  },
  "& .react-calendar__month-view__weekdays__weekday": {
    textTransform: 'capitalize',
    fontSize: '14px',
    color: '#64748B',
    fontWeight: 400,
    textDecoration: 'none',
  },
  "& .react-calendar__month-view__weekdays__weekday abbr": {
    textDecoration: 'none',
  },
  "& .react-calendar__tile": {
    fontSize: '14px',
    fontWeight: 400,
    color: '#0F172A',
    width: '50px',
    height: '50px',
  },
  "& .react-calendar__tile.react-calendar__tile--range": {
    background: '#F1F5F9',
    color: '#044352',
    borderRadius: '0px',
  },
  "& .react-calendar__tile.react-calendar__tile--rangeStart": {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
    background: '#F1F5F9', 
    color: '#044352',
  },
  "& .react-calendar__tile.react-calendar__tile--rangeEnd": {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    background: '#F1F5F9',
    color: '#044352',
  },
  "& .react-calendar__tile.react-calendar__tile--active, .react-calendar__tile.react-calendar button:enabled": {
    background: '#1B4FE4',
    color: '#F8FAFC',
    fontSize: '14px',
    fontWeight: 700,
  },
  "& .react-calendar__tile.react-calendar__tile--active:hover, .react-calendar__tile.react-calendar button:enabled:hover": {
    background: '#F1F5F9',
    color: '#044352',
    pointerEvents: "none",
},
  "& .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth": {
    color: '#94A3B8'
  },
  "& .action-button-div": {
    [theme.breakpoints.down(450)]: {
      width:"calc(275px - 30px)"
    },
    width:"calc(330px - 30px)",
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 15px',
    gap: '20px',
    backgroundColor: "#FFFFFF"
  },
  "& .action-button-div button": {
    width: '140px',
    height: '50px',
    borderRadius: '7.21px',
    fontFamily: "DIN Next LT Arabic Regular",
    textTransform: 'capitalize',
    fontSize: '14.42px',
  },
  "& .action-button-div .cancel-btn": {
    background: '#F1F5F9',
    color: '#94A3B8',
    boxShadow: "0px 3.6px 3.6px 0px #00000040"
  },
  "& .action-button-div .save-btn.active": {
    background: '#1B4FE4',
    color: '#FFFFFF'
  },
  "& .action-button-div .save-btn": {
    background: '#F1F5F9',
    color: '#94A3B8',
    boxShadow: "0px 3.6px 3.6px 0px #00000040"
  },
});

const StyledTooltip = styled(Box)(({ theme }) => ({
  height: "fit-content",
  backgroundColor: "#1B4FE4",
  padding: "16px 24px",
  borderRadius: "12px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: "1px",
 

  "& .tooltip-text": {
    fontSize: "24px",
    color: "#FFFFFF",
    fontFamily: "DIN Next LT Arabic Regular",
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px'
    },
  },

  "& .tooltip-date": {
    display: "inline-block",
    padding: 10,
    color: "#FFFFFF",
    fontSize: "18px",
    fontFamily: "DIN Next LT Arabic Regular",
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    },
  },
}));
// Customizable Area End
