// Customizable Area Start
import React from "react";
import {
    Typography,
    Button,
    Grid,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    TableRow,
    TableCell,
} from "@material-ui/core";
import {
    styled
} from "@material-ui/core/styles";
import DocumentationFolderController, {
    Props,
} from "./DocumentationFolderController";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FolderComponent from "../../../components/src/FolderComponent.web";
import DownloadedDocs from "../../../components/src/DownloadedDocs.web";
import { generateUniqueKey } from "../../../components/src/utils.web";
import { Pagination } from "@material-ui/lab";

// Customizable Area End

export default class DocumentStorage extends DocumentationFolderController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    MainWrapper = styled(Box)(({ theme }) => ({
        zIndex: -1,
        position: "absolute",
        [theme.breakpoints.up(900)]:{
          zIndex: 0,
          position: "inherit",
        },
        "& .container": {
            [theme.breakpoints.down(900)]: {
                width: "calc(100vw - 4rem)",
                padding: "5px 10px 10px 3rem",
              position: "absolute",
              top: "0",
              zIndex: 99,
            },
            [theme.breakpoints.down(750)]: {

            },
            width: "calc(100vw - 315px - 5rem)",
            padding: "4rem 10px 10px 2rem",
            height:"calc(100vh - 4rem - 10px)",
            overflowY:"scroll",
          },
        "& .statusContentFlexBox": { display: 'flex', gap: 10, flexDirection: 'column', [theme.breakpoints.down(750)]: { flexDirection: 'row' } },
        "& .iconLineFlexBox": {
            display: "flex", alignItems: "center", gap: '20px',
            marginBottom: '24px', [theme.breakpoints.down(750)]: { flexDirection: 'column' }
        },
        "& .horizontalDivider": {
            [theme.breakpoints.down(750)]: { height: '40px', width: '6px' }, height: '6px', borderRadius: '5px',
            width: '100%', margin: '20px 0p'
        },
        "& .iconButton": { padding: theme.spacing(1) },
        "& .icon": { width: 24, height: 24 },
        "& .buttonStyle": {
            textTransform: 'none',
            fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '12px', fontStyle: 'normal',
            fontWeight: 700, lineHeight: '18px'
        },
        "& .stepFont": {
            color: '#64748B', fontFamily: "DIN Next LT Arabic Regular", fontSize: '14px', fontStyle: 'normal',
            fontWeight: 400, lineHeight: '22px'
        },
        "& .titleFont": {
            color: '#1E293B',
            fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '24px',
            marginBottom: '16px'
        },
        "& .headingText": {
            [theme.breakpoints.down(750)]: {
                fontSize: '18px',
                lineHeight: '30px',
            },
            overflow: 'hidden',
            color: '#1E293B',
            textOverflow: 'ellipsis',
            fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '30px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '40px',
            letterSpacing: '-0.15px',
        },
        "& .customButton": {
            [theme.breakpoints.down(900)]: {
                padding: "14px 8px"
            },
            [theme.breakpoints.down(750)]: {
                padding: '7px 5px !important',
                "& .MuiButton-startIcon": {
                    marginRight: '0'
                }
            },
            display: 'flex', padding: '10px 16px', justifyContent: 'center', alignItems: 'center',
            gap: '8px', borderRadius: '8px',
            background: '#1B4FE4',
            width: "100%",
            whiteSpace: 'nowrap',
        },
        "& .buttonText": {
            [theme.breakpoints.down(1451)]: {
                fontSize: "14px",
            },
            [theme.breakpoints.down(1370)]: {
                fontSize: "13px",
            },
            [theme.breakpoints.down(1262)]: {
                fontSize: "12px",
            }, [theme.breakpoints.down(900)]: {
                fontSize: "12px",
            }, [theme.breakpoints.down(750)]: {
                display: 'none',
            },
            color: '#FFF', textTransform: "none", fontFamily: "DIN Next LT Arabic Bold", fontSize: '16px', fontStyle: 'normal',
            fontWeight: 700, lineHeight: '24px',
            flexWrap: "nowrap",
            whiteSpace: 'nowrap'
        }, "& .newRequestText": {
            color: '#1E293B',
            fontFamily: "DIN Next LT Arabic Bold", fontSize: '24px',
            fontStyle: 'normal', fontWeight: 700, lineHeight: '26px', marginBottom: '24px',
        },
        "& .transactionIdText": {
            color: '#334155', fontFamily: "DIN Next LT Arabic Regular",
            fontSize: '16px', fontStyle: 'normal',
            fontWeight: 400, lineHeight: '24px',
            marginBottom: '24px'
        },
        "& .trasactionValueText": {
            color: "#1E293B", fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '18px', fontStyle: 'normal',
            fontWeight: 700, lineHeight: '26px',
        },
        "& .table": {
            minWidth: 650,
        },
        "& .depositHistoryText": {
            [theme.breakpoints.down(750)]: {
                fontSize: '18px'
            },
            color: '#1E293B',
            fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '32px',
            letterSpacing: '-0.12px',
            marginBottom: '8px'
        },
        "& .paginationRoot": {
            '& .MuiPaginationItem-root': {
                borderRadius: 4,
                marginRight: '16px',
                fontFamily: 'DIN Next LT Arabic Bold',
                fontWeight: 700,
            },
            '& .MuiPaginationItem-page:not(.Mui-selected)': {
                border: '1px solid #94A3B8',
                color: '#94A3B8',
            },
        },
        "& .headingStyle": {
            [theme.breakpoints.down(750)]: {
                fontSize: '16px'
            },
            color: '#1E293B', fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '18px', fontStyle: 'normal',
            fontWeight: 700, lineHeight: '26px',
        },
        "& .tableContentText": {
            fontFamily: "DIN Next LT Arabic Regular",
            fontSize: '16px', fontStyle: 'normal',
            fontWeight: 400, lineHeight: '24px',
        },
        "& .tableBtn": {
            textTransform: 'capitalize',
            width: '120px', padding: '12px 16px',
            fontFamily: "DIN Next LT Arabic Regular", fontSize: '16px',
            fontStyle: 'normal', fontWeight: 400, lineHeight: '24px',
        },
        "& .amountText": {
            color: '#F59E0B', fontFamily: "DIN Next LT Arabic Regular", fontSize: '16px', fontStyle: 'normal', fontWeight: 400, lineHeight: '24px'
        },
        "& .headingFlexBox": { display: 'flex', justifyContent: 'space-between', marginBottom: '78px' },
        "& .depositHistoryContainer": { marginTop: '96px' },
        "& .tableDeskTopView": { display: 'block', [theme.breakpoints.down(750)]: { display: 'none' } },
        "& .tableMobileView": {
            display: 'none',
            [theme.breakpoints.down(750)]: { display: 'block' }
        },
        "& .addIconStyle": {
            marginTop: '3px', color: "#fff",
            [theme.breakpoints.down(750)]: { fontSize: '24px' }
        },
        "& .responsiveCardContainer": {
            display: 'flex',
            alignItems: 'center',
            gap: 3,
            flexWrap: 'wrap',
            [theme.breakpoints.down('sm')]: { flexDirection: 'column' },
        },
        "& .responsiveCard": {
            flexBasis: '600px', flexGrow: 1,
            minWidth: '300px', width: '100%',
            padding: 3,
            borderRadius: 1,
            [theme.breakpoints.down('sm')]: {
                flexBasis: '100%',
                flexDirection: 'column',
            },
        },
        "& .cardContent": { flexGrow: 1, padding: '8px 12px' },
        "& .stageCard": {
            flex: '1 1 300px',
            [theme.breakpoints.down('sm')]: {
                flexBasis: '100%',
                flexDirection: 'column',
                width: '100%'
            },
        },
        "& .stageCardContent": {
            padding: '24px',
            borderRadius: '8px',
            [theme.breakpoints.down('sm')]: {
                flexBasis: '100%',
                flexDirection: 'column',
            },
        },
        "& .boxContainer": {
            [theme.breakpoints.down(1183)]: {
                top: 200,
            },
            [theme.breakpoints.down(975)]: {
                top: 220,
            },
            [theme.breakpoints.down(900)]: {
                top: 65,
                position: "fixed" as "fixed",
            },
            // width: "100%",
            position: "absolute" as "absolute",
            top: 180,
            right: 35,
            // height: "100%",
            zIndex: 1000,
            display: "flex",
            justifyContent: "center" as "center",
            alignItems: "center",
            borderRadius: "8px"
            // background: "rgba(0, 0, 0, 0.6)",
        },
        "& .backBox": {
            borderRadius: "12px",
            display: "flex",
            flexDirection: "column" as "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "24px",
            backgroundColor: "#FFF",
            top: 0,
            zIndex: 31,
            boxShadow:
                "0px 25px 50px 0px rgba(0, 0, 0, 0.15), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",

        },
        "& .popupBox": {
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column" as "column",
            justifyContent: "flex-start",
            alignItems: "center",
            backgroundColor: "#FFFFFF",
            top: 0,
            boxShadow: "0px 2px 4px 0px #00000026",
            width: "fit-content",
            position: "relative" as "relative"
        },
        "& .profitData": {
            fontSize: "16px",
            fontFamily: "DIN Next LT Arabic Regular",
            padding: "12px 16px",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            color: "#3C3E49",
            textTransform: "none",
            backgroundColor: "#ffffff",
        },
        "& .active": {
            backgroundColor: "#1B4FE4",
            color: "#F8FAFC",
            fontSize: "16px",
            fontFamily: "DIN Next LT Arabic Regular",
            padding: "12px 16px",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            textTransform: "none",
        },
        "& .profitDatas2": {
            border: "2px solid red",
            backgroundColor: "#ffffff",
            width: "100%",
            textTransform: "none",
            color: "#000000",
            fontSize: "16px",
            fontFamily: "DIN Next LT Arabic Regular"
        },
        "& .header": {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: theme.spacing(3),
        },
        "& .folderGrid": {
            marginBottom: theme.spacing(4),
        },
        "& .folder": {
            padding: theme.spacing(2),
            textAlign: 'center',
        },
        "& .fileIcon": {
            fontSize: 48,
            marginBottom: theme.spacing(1),
        },
        "& .fileItem": {
            padding: theme.spacing(2),
            textAlign: 'center',
        },
    }));

    
    // Customizable Area End
    render() {
        // Customizable Area Start
        const {paginationData} = this.state
        const parentId = this.getParentId();
        const { folders, folderName, editingFolderId, recentDownloads, fileOrFolderType, mainFolderLength } = this.state;
        const folderKeys = Object.keys(folders);
        const parentFolderKeys = folderKeys.filter((folderKey) => {
            const folder = folders[folderKey];
            if (!parentId) {
                if (folder.parentFolderId) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return folder.parentFolderId == parentId;
            }
        });

        return (
            <div style={{ display: "flex", width: "fit-content" }}>
                <Box>
                    <NavigationMenu id="" navigation={this.props.navigation}></NavigationMenu>
                </Box>
                <Dialog open={this.state.isNewFolderDialogOpen}>
                    <DialogTitle style={{ fontFamily: "DIN Next LT Arabic Bold !important", fontSize: '16px', fontStyle: 'normal', }}>{editingFolderId ? `Update ${fileOrFolderType}` : `Create New Folder`}</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label={`${fileOrFolderType} Name`}
                            type="text"
                            fullWidth
                            value={folderName}
                            style={{ fontFamily: "DIN Next LT Arabic Bold !important", fontSize: '16px', fontStyle: 'normal', }}
                            onChange={this.changeFolderName}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button style={{ textTransform: 'initial', fontFamily: "DIN Next LT Arabic Bold", fontSize: '16px', fontStyle: 'normal', }} onClick={this.handleCloseDialog} color="primary">
                            Cancel
                        </Button>
                        <Button
                            disabled={!folderName}
                            style={{
                                fontFamily: "DIN Next LT Arabic Bold", fontSize: '16px', fontStyle: 'normal',
                                backgroundColor: this.handleCondition(folderName, '#1B4FE4', '#D3D3D3'),
                                color: this.handleCondition(folderName , '#fff' , '#A9A9A9'),
                                padding: '8px 16px',
                                textTransform: 'initial',
                            }}
                            onClick={this.onCreateFolder}
                            color="primary"
                        >
                            {this.handleCondition(editingFolderId, 'Update', 'Create')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <this.MainWrapper>
                    <Box className="container">
                        <Box className="headingFlexBox">
                            <Typography className="headingText">My Documents</Typography>
                            <Box style={{ display: 'flex', justifyContent: 'flex-end', gap: '20px', alignItems: 'center' }}>
                                <Button
                                    data-test-id="showAddBtn"
                                    onClick={this.handleCreateNewFolder}
                                    startIcon={<AddCircleOutlineIcon
                                        className="addIconStyle" />}
                                    className="customButton">
                                    <span className="buttonText">Create New Folder</span>
                                </Button>
                            </Box>
                        </Box>
                        {
                            <Box>
                                <Typography className="newRequestText">{`My Folders (${mainFolderLength})`}</Typography>
                                {
                                    parentId && (
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                            <Button
                                                color="primary"
                                                onClick={this.onBack}
                                                startIcon={<ArrowBackIcon />}
                                            />


                                            <Button
                                                variant="contained"
                                                style={{ color: 'rgb(27, 79, 228)', textTransform: 'initial', fontFamily: 'DIN Next LT Arabic Bold' }}
                                                component="label"
                                                startIcon={<CloudUploadIcon />}
                                            >
                                                Add Document
                                                <input
                                                    type="file"
                                                    hidden
                                                    multiple
                                                    accept=".doc,.docx,.pdf,.xls,.xlsx,.csv,.jpg,.jpeg,.png"
                                                    onChange={this.handleFileUpload}
                                                    data-testid="filePicker"
                                                />
                                            </Button>
                                        </Box>
                                    )
                                }
                                <Grid container spacing={2}>
                                    {parentFolderKeys.length === 0 && <Typography style={{ width: '100%', textAlign: 'center' }} className="tableContentText">No data found</Typography>}
                                    {parentFolderKeys.map((parentFolderKey) => {
                                        const parentFolder = folders[parentFolderKey];
                                        const deleteFunction = parentFolder.type === "folder" ? this.deleteFolder : this.deleteFile
                                        const folderKey = generateUniqueKey(`folder_${parentFolder.id}`);
                                        return (
                                            <FolderComponent
                                                key={folderKey}
                                                folderOrFile={parentFolder}
                                                onViewFolder={this.onViewFolder}
                                                onDeleteFileOrFolder={deleteFunction}
                                                onEditFolder={this.updateFolderName}
                                                renderFileIcon={this.renderFileIcon}
                                                downloadDocument={this.downloadDocumentRequest}
                                            />
                                        );
                                    })}
                                </Grid>
                            </Box>
                        }
                        <Box className="depositHistoryContainer">
                            <Typography className="depositHistoryText">{`Recent Downloads (${recentDownloads.length})`}</Typography>
                            <Grid container spacing={2}>
                                {recentDownloads.length === 0 && <Typography style={{ width: '100%', textAlign: 'center' }} className="tableContentText">No data found</Typography>}
                                {
                                    recentDownloads.map((downloadedFile) => {
                                        return (
                                            <DownloadedDocs
                                                key={downloadedFile.id}
                                                downloadedFile={downloadedFile}
                                                onDeleteFile={this.deleteFile}
                                                onEditFile={this.renameDownloadedFile}
                                                onViewFile={this.onViewFolder}
                                                renderFileIcon={this.renderFileIcon}
                                            />
                                        )
                                    })
                                }
                            </Grid>
                            {this.handleCondition(paginationData.totalPages > 1 , (
                                                <TableRow style={{display:"flex", justifyContent:"flex-end", alignItems:"center"}}>
                                                    <TableCell colSpan={5}>
                                                        <Box display="flex" justifyContent="flex-end">
                                                            <Pagination
                                                                data-test-id="pagination"
                                                                count={paginationData.totalPages}
                                                                page={paginationData.currentPage}
                                                                onChange={(event, page) => this.handlePaginatioChange(event, page)}
                                                                color="primary"
                                                                size="small"
                                                                className="paginationRoot"
                                                            />
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ), "")
                                        }
                        </Box>

                    </Box>
                </this.MainWrapper>
            </div>
        )
        // Customizable Area End
    }
}

// Customizable Area Start

// Customizable Area End
