Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getContractContentApiEndPoint = "/bx_block_custom_form/contract_forms";
exports.postContractPdfApiEndPoint = "bx_block_custom_form/signed_contracts";
exports.createEnvelop = "/bx_block_custom_form/signed_contracts/signed_contract_form";
exports.getUrl ="/bx_block_docu_sign/signs";
exports.acountLetterEndpoint = "bx_block_custom_form/account_confirmation_letters";
exports.getUserDetailsEndpoint = "account_block/accounts/get_profile"
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';
// Customizable Area End