import React from "react";
// Customizable Area Start
import {
  Box,
  styled,
  createTheme,
  Typography,
  Button,
  Card,
  TableRow,
  TableCell,
} from "@material-ui/core";
import Calendar from "react-calendar";
import { Area, AreaChart, Label, Pie, PieChart, Tooltip, XAxis, YAxis } from "recharts";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
import {
  PlusIcon,
  searchIcon,
  arrow,
  drop,
  fourline,
  calendar,
  plusButton,
  searchButton,
  downloadIcon,
  groupPng,
  rightArrow,
  backAr,
} from "./assets";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { withTranslation } from "react-i18next";
import {webConfigJSON} from "./WeeklyPerformanceController.web";
// Customizable Area End
import WeeklyPerformanceController, {
  Props,
  TStatus,
} from "./WeeklyPerformanceController.web";
import { Pagination } from "@material-ui/lab";

export default class WeeklyPerformance extends WeeklyPerformanceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  MainWrapper = styled(Box)({
    zIndex: -1,
    position: "absolute",
    width: "calc(100vw - 358px)",
    [theme.breakpoints.up(900)]: {
      zIndex: 0,
      position: "inherit",
    },
    "& .container": {
      [theme.breakpoints.down(900)]: {
        position: "absolute",
        top: "0",
        padding: "0",
        zIndex: 99,
        width: "fit-content",
        height: "calc(100vh - 1px)",
      },
      width: "calc(100vw - 358px)",
      height: "calc(100vh - 4.5rem)",
      padding: "4rem 10px 10px 0rem",
      overflow: "scroll",
      "&::-webkit-scrollbar": {
        display: "none",
        },
    },
    "& .linkBox": {
      [theme.breakpoints.down(900)]: {},
    },
    "& .dashboardBox": {
      [theme.breakpoints.down(900)]: {
        width: "calc(100vw - 75px)",
        paddingTop:"4.5px"
      },
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      textAlign: "center",
      padding: "0 20px 0 40px",
      width: "calc(100% - 60px)",
      minWidth: "calc(100% - 60px)",
    },
    "& .dashboardStyle": {
      textAlign: "center",
      color: "#334155",
      fontSize: "30px",
      fontFamily: "DIN Next LT Arabic Bold",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& .dashboardBox2": {
      [theme.breakpoints.down(380)]: {
        gap: "10px",
      },
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      gap: "20px",
      width: "fit-content",
    },
    "& .dashboardSearch": {
      fontSize: "16px",
      border: "none",
      color: "#94A3B8",
      fontFamily: "DIN Next LT Arabic Regular",
      width: "100%",
      outline: "none",
    },
    "& .requestButton": {
      [theme.breakpoints.down(900)]: {
        padding: "14px 8px",
      },
      [theme.breakpoints.down(750)]: {
        display: "none",
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#FFFFFF",
      backgroundColor: "#1B4FE4",
      gap: "8px",
      padding: "12px 16px",
      borderRadius: "8px",
      flexWrap: "nowrap",
      cursor:"pointer"
    },
    "& .buttonOne": {
      [theme.breakpoints.down(1451)]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down(1370)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(1262)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(900)]: {
        fontSize: "12px",
      },
      color: "#FFFFFF",
      fontWeight: 700,
      backgroundColor: "#1B4FE4",
      fontSize: "16px",
      border: "none",
      fontFamily: "DIN Next LT Arabic Bold",
      flexWrap: "nowrap",
      cursor:"pointer"
    },
    "& .serachBox": {
      [theme.breakpoints.down(900)]: {
        width: "calc(30% - 16px)",
        padding: "5.5px",
      },
      [theme.breakpoints.down(750)]: {
        display: "none",
      },
      border: "1px solid #64748B",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      padding: "9px 8px",
      borderRadius: "8px",
      gap: "8px",
      width: "calc(30% - 16px)",
    },
    "& .serachBoxMobile": {
      [theme.breakpoints.up(750)]: {
        display: "none",
      },
      border: "1px solid #64748B",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      padding: "4px 8px",
      borderRadius: "8px",
      gap: "8px",
      width: "calc(50% - 16px)",
    },
    "& .serchStyle": {
      width: "24px",
      height: "32px",
    },
    "& .plusStyle": {
      [theme.breakpoints.down(1276)]: { width: "23px", height: "23px" },
      [theme.breakpoints.down(1217)]: { width: "20px", height: "20px" },
      [theme.breakpoints.down(1120)]: { width: "17px", height: "17px" },
      [theme.breakpoints.down(1021)]: { width: "15px", height: "15px" },
      [theme.breakpoints.down(900)]: {},
      cursor:"pointer"
    },
    "& .totalReviewBox": {
      [theme.breakpoints.down(900)]: {
        width: "calc(100vw - 75px)",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "48px 40px",
      height: "fit-content",
      gap: "24px",
      width: "calc(100% - 80px)",
      minWidth: "calc(100% - 80px)",
    },
    "& .reviewBox": {
      [theme.breakpoints.down(900)]: {
        width: "170",
        height: "130px",
        gap: "8px",
        padding: "4px 8px",
      },

      [theme.breakpoints.down(750)]: {
        display: "none",
      },
      width: "calc(25% - 48px)",
      height: "150px",
      padding: "16px 24px",
      gap: "16px",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      flexDirection: "column",
    },
    "& .totalInvest": {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .totalText": {
      fontSize: "16px",
      fontWeight: 400,
      fontFamily: "DIN Next LT Arabic Light",
    },
    "& .totalPercent": {
      padding: "2px 8px",
      borderRadius: "40px",
      color: "#FFFFFF",
      backgroundColor: "#EF4444",
      fontSize: "12px",
      fontWeight: 500,
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "DIN Next LT Arabic Regular",
    },
    "& .totalNumber": {
      [theme.breakpoints.down(900)]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down(750)]: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        fontSize: "18px",
        width: "100%",
      },
      fontSize: "18px",
      width: "100%",
      fontWeight: 700,
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "& .reportsContainer": {
      [theme.breakpoints.down(900)]: {
        width: "calc(100vw - 75px)",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "calc(100% - 80px)",
      padding: "48px 40px",
      gap: "48px",
      flexDirection: "column",
    },
    "& .reportsBox": {
      [theme.breakpoints.down(480)]: {},
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "8px",
      width: "calc(60%- 20px)",
    },
    "& .report": {
      [theme.breakpoints.down(480)]: {},
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
    },
    "& .expensesText": {
      [theme.breakpoints.down(480)]: {},
      fontSize: "16px",
      color: "#1B4FE4",
      fontWeight: 700,
      fontFamily: "DIN Next LT Arabic Bold",
      cursor: "pointer",
    },
    "& .expensesButton": {
      color: "#475569",
      fontWeight: 400,
      fontFamily: "DIN Next LT Arabic Light",
      fontSize: "14px",
      borderRadius: "26px",
      border: " 1px solid #94A3B8",
      padding: "8px 12px 6px 10px",
      width: "calc(100% - 22px)",
    },
    "& .activityTable": {
      [theme.breakpoints.down(900)]: {
        width: "calc(100vw - 20px)",
      },
      [theme.breakpoints.down(750)]: {
        flexDirection: "column",
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "calc(100% - 30px)",
      minWidth: "calc(100% - 30px)",
      height: "fit-content",
      gap: "24px",
      padding: "48px 10px",
    },
    "& .leftActivity": {
      [theme.breakpoints.down(750)]: {
        width: "calc(100% - 52px)",
      },
      display: "flex",
      justifyContent: "space-between",
      gap: "24px",
      alignItems: "center",
      width: "100%",
      padding: "16px 26px",
      flexDirection: "column",
    },
    "& .activityText": {
      [theme.breakpoints.down(900)]: {
        display: "none",
      },
      [theme.breakpoints.down(750)]: {
        fontSize: "16px",
      },
      width: "100%",
      fontSize: "18px",
      color: " #1E293B",
      fontWeight: 700,
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "& .activityTextRating": {
      [theme.breakpoints.down(750)]: {
        fontSize: "16px",
      },
      width: "100%",
      fontSize: "18px",
      color: " #1E293B",
      fontWeight: 700,
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "& .activityTextSort": {
      [theme.breakpoints.up(900)]: {
        display: "none",
      },
      width: "100%",
      fontSize: "18px",
      color: " #1E293B",
      fontWeight: 700,
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "   & .activityrightttext": {
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      fontSize: "18px",
      color: " #1B4FE4",
      fontWeight: 700,
      fontFamily: "DIN Next LT Arabic Bold",
      gap: "8px",
      cursor:"pointer"
    },
    "& .rightActivity": {
      [theme.breakpoints.down(480)]: {},
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    "& .waweStyle": {
      [theme.breakpoints.down(900)]: {
        width: "10rem",
      },
      [theme.breakpoints.down(831)]: {
        width: "9rem",
      },
      width: "100%",
    },
    "& .activityBox": {
      [theme.breakpoints.down(480)]: {},
      width: "100%",
      gap: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .calendar": {
      [theme.breakpoints.down(480)]: {},
      fontWeight: 700,
      fontSize: "16px",
      color: "#1B4FE4",
      whiteSpace: "nowrap",
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "& .calendarWeek": {
      [theme.breakpoints.down(480)]: {},
      fontWeight: 700,
      fontSize: "16px",
      color: "#1B4FE4",
      whiteSpace: "nowrap",
      fontFamily: "DIN Next LT Arabic Bold",
      cursor: "pointer",
    },
    "& .sortBy": {
      [theme.breakpoints.up(750)]: {
        display: "none",
      },
      fontWeight: 700,
      fontSize: "16px",
      color: "#1B4FE4",
      whiteSpace: "nowrap",
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "& .sortDropIcon": {
      [theme.breakpoints.up(750)]: {
        display: "none",
      },
    },
    "& .sortDropIcon2": {
      [theme.breakpoints.down(750)]: {
      },
    },
    "& .downloadReport": {
      [theme.breakpoints.down(750)]: {
        display: "none",
      },
      fontWeight: 700,
      fontSize: "16px",
      color: "#1B4FE4",
      fontFamily: "DIN Next LT Arabic Bold",
      width: "100%",
    },
    "& .reportss": {
      [theme.breakpoints.down(480)]: {},
      fontWeight: 700,
      fontSize: "18px",
      color: "#1E293B",
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "& .headingBox": {
      width: "100%",
      gap: "24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .headingBoxT": {
      [theme.breakpoints.down(750)]: {
        display:"none"
      },
      width: "100%", gap: "80px", display: "flex", alignItems: "center",  justifyContent: "space-between"},
    "& .headingStyle": {
      [theme.breakpoints.down(750)]: {
        fontSize: "16px"},
      fontSize: "14px",      width: "100%",
      textAlign: "center",
      fontWeight: 700,      fontFamily: "DIN Next LT Arabic Bold"},
    "& .headingStyleT": {
      [theme.breakpoints.down(750)]: {
        fontSize: "16px"},
      fontSize: "18px",      width: "100%",
      textAlign: "center", fontWeight: 700,
      fontFamily: "DIN Next LT Arabic Bold",
    },
    "& .headingStyleName": {
      [theme.breakpoints.down(750)]: {
        fontSize: "16px"},
      fontSize: "14px",     width: "100%",
      textAlign: "left",
      fontWeight: 700,fontFamily: "DIN Next LT Arabic Bold"    },
    "& .headingStyleNameT": {
      [theme.breakpoints.down(750)]: {
        fontSize: "16px"

      },      fontSize: "18px",
      width: "100%",
      textAlign: "left",      fontWeight: 700,      fontFamily: "DIN Next LT Arabic Bold",
    },
    "& .itemBox": {
      width: "100%",
      gap: "24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingBottom: "16px",
    },
    "& .itemBoxT": {
      width: "100%",
      gap: "80px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingBottom: "16px",
    },
    "& .itemData": {
      width: "100%",
      fontSize: "16px",
      color: "#F59E0B",
      fontWeight: 400,
      textAlign: "center",
      fontFamily: "DIN Next LT Arabic Regular",
    },
    "& .itemDataStatus": {
      width: "100%",
      fontSize: "16px",
      color: "#334155",
      fontWeight: 400,
      textAlign: "center",
      fontFamily: "DIN Next LT Arabic Regular",
      borderRadius: "8px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .itemDataStatus2": {
      fontSize: "16px",
      color: "#334155",
      fontWeight: 400,
      textAlign: "center",
      fontFamily: "DIN Next LT Arabic Regular",
      padding: "12px 16px",
      borderRadius: "8px",
    },
    "& .itemDataName": {
      width: "100%",
      fontSize: "16px",
      color: "#334155",
      fontWeight: 400,
      textAlign: "left",
      fontFamily: "DIN Next LT Arabic Regular",
    },
    "& .itemDataBal": {
      [theme.breakpoints.down(480)]: {},
      width: "100%",
      fontSize: "12px",
      fontWeight: 500,
      textAlign: "center",
      borderRadius: "40px",
      color: "#fff",
      fontFamily: "DIN Next LT Arabic Regular",
    },
    "& .bellIcon": {
      [theme.breakpoints.down(900)]: {
        width: "1rem",
      },
      [theme.breakpoints.down(750)]: {
        width: "24px",
        height: "24px",
      },
    },
    "& .Expenses": {
      [theme.breakpoints.down(900)]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "48px",
      },
      [theme.breakpoints.down(750)]: {
        gap: "18px",
      },
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      gap: "48px",
      width: "fit-content",
    },
    "& .expenseDrop": {
      [theme.breakpoints.down(900)]: {},
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "5px",
      width: "100%",
    },
    "& .dashBox": {
      [theme.breakpoints.down(900)]: {},
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      paddingBottom: "10px",
      with: "30px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "100%",
    },
    "& .ButtonPlus": {
      [theme.breakpoints.down(750)]: {
        width: "40px",
        height: "40px",
      },
      [theme.breakpoints.up(750)]: {
        display: "none",
      },
    },
    "& .mobileSearch": {
      [theme.breakpoints.down(750)]: {
        width: "40px",
        height: "40px",
      },
      [theme.breakpoints.up(750)]: {
        display: "none",
      },
    },
    "& .circlePager": {
      [theme.breakpoints.up(750)]: {
        display: "none",
      },
      padding: "20px 10px",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "12px",
    },
    "& .circle": {
      [theme.breakpoints.down(750)]: {
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        backgroundColor: "#E2E8F0",
      },
    },
    "& .circle.active": {
      backgroundColor: "#1B4FE4",
    },
    "& .threeDots": {
      [theme.breakpoints.down(750)]: {
        width: "35px",
        height: "35px",
      },
      [theme.breakpoints.up(750)]: {
        display: "none",
      },
    },
    "& .statusBox": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "24px",
      width: "100%",
      textAlign: "center",
    },
    "& .calenderIcon": {
      [theme.breakpoints.down(750)]: {
        width: "24px",
        height: "24px",
      },
      [theme.breakpoints.up(750)]: {
        display: "none",
      },
    },
    "& .acountIcon": {
      [theme.breakpoints.down(750)]: {
        width: "24px",
        height: "24px",
      },
    },
    "& .mobileReviewBox": {
      [theme.breakpoints.down(750)]: {},
      [theme.breakpoints.up(750)]: {
        display: "none",
      },
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      flexDirection: "column",
      gap: "16px",
      padding: "0 20px 0 0",
    },
    "& .mobileTextBox": {
      [theme.breakpoints.down(750)]: {},
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    "& .mobileTextBoxNum": {
      [theme.breakpoints.down(750)]: {},
      backgroundColor: "#EF4444",
      borderRadius: "40px",
      textAlign: "center",
      width: "48px",
      height: "22px",
      padding: "2px 8px",
    },
    "& .mobileNum": {
      [theme.breakpoints.down(750)]: {},
      color: "#FFFFFF",
      fontSize: "14px",
      fontFamily: "DIN Next LT Arabic Regular",
    },
    "& .mobileAmount": {
      [theme.breakpoints.down(750)]: {},
      display: "flex",
      width: "100%",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    "& .mobileImage": {
      [theme.breakpoints.down(750)]: {},
      display: "flex",
      width: "100%",
      height: "23px",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    "& .profitContainer": {
      position: "fixed" as "fixed",
      top: "100%",
      height: "100%",
      zIndex: 30,
      display: "flex",
      justifyContent: "center" as "center",
      alignItems: "flex-start",
      width: "180px",
    },
    "& .profitContainerButton": {
      [theme.breakpoints.up(750)]: {
        display: "none"
      },
      top: "7%",
      zIndex: 30,
      width: "250px",
    },
    "& .popupBox2": {
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column" as "column",
      justifyContent: "flex-start",
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      boxShadow: "0px 2px 4px 0px #00000026",
      marginTop:"10px"
    },
    "& .popupBox": {
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column" as "column",
      justifyContent: "flex-start",
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      top: 0,
      boxShadow: "0px 2px 4px 0px #00000026",
      paddingTop:"20px"
    },
    "& .profitData": {
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Light",
      padding: "12px 16px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      color: "#3C3E49",
    },
    "& .active": {
      backgroundColor: "#1B4FE4",
      color: "#F8FAFC",
    },
    "& .mobileMessage": {
      [theme.breakpoints.up(750)]: {
        display: "none",
      },
      color: "#94A3B8",
      fontFamily: "DIN Next LT Arabic Light",
      fontSize: "16px",
    },
    "& .tableMobileView": {
      display: "none",
      [theme.breakpoints.down(750)]: { display: "block", width: "100%" },
    },
    "& .tableMobileViewT": {
      display: "none",
      [theme.breakpoints.down(750)]: { display: "block", padding:"48px 40px" },
    },
    "& .responsiveCardContainer": {
      display: "flex",
      alignItems: "center",
      gap: 3,
      flexWrap: "wrap",
      [theme.breakpoints.down("sm")]: { flexDirection: "column" },
    },
    "& .cardContent": { flexGrow: 1, padding: "8px 12px" },
    "& .responsiveCard": {
      flexBasis: "600px",
      flexGrow: 1,
      minWidth: "300px",
      width: "100%",
      padding: 3,
      borderRadius: 1,
      [theme.breakpoints.down("sm")]: {
        flexBasis: "100%",
        flexDirection: "column",
      },
    },
    "& .headingStyleMobile": {
      [theme.breakpoints.down(750)]: {
        fontSize: "16px",
      },
      color: "#1E293B",
      fontFamily: "DIN Next LT Arabic Bold",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "26px",
    },
    "& .tableContentText": {
      fontFamily: "DIN Next LT Arabic Regular",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
    },
    "& .tableBtn": {
      textTransform: "capitalize",
      width: "120px",
      padding: "12px 16px",
      fontFamily: "DIN Next LT Arabic Regular",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
    },
    "& .lastBox": {
      [theme.breakpoints.down(750)]: {
        display: "none",
      },
      width: "100%",
      gap: "24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "column",
    },
    "& .paginationRoot": {
      "& .MuiPaginationItem-root": {
        borderRadius: 4,
        marginRight: "16px",
      },
      "& .MuiPaginationItem-page:not(.Mui-selected)": {
        border: "1px solid #94A3B8",
        color: "#94A3B8",
      },
    },
    "& .transactionListBox":{
      [theme.breakpoints.down(750)]: {
        padding:"0px"
      },
      padding:"48px 40px"
    },
    "& .ratingBox":{
      [theme.breakpoints.down(500)]: {
        gap: "16px",
      },
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      gap: "46px",
    },
    "& .graphContainer":{ 
      width: '100%',
      overflowX: 'auto',
      paddingLeft:10,
      "& .graphContainer:hover": {
        overflowX: "auto"
      }
    },
    "& .calBox":{
      [theme.breakpoints.down(500)]: {
      },
      borderRadius: "12px",
      gap: "24px",
      backgroundColor: "#FFF",
      zIndex: 31,
      boxShadow:
        "0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",  
        height:"55%",
        overflow:"auto",
    }
  });

  CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            height: "fit-content",
            backgroundColor: "#1B4FE4",
            top: 0,
            paddingTop: "0px",
            padding: "16px 24px",
            borderRadius: "12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "1px",
          }}
        >
          <p
            style={{
              fontSize: "24px",
              color: "#FFFFFF",
              fontFamily: "DIN Next LT Arabic Regular",
            }}
          >
            {payload[0].payload.amount}
          </p>
          <div>
            <div style={{ display: "inline-block", padding: 10 }}>
              <div style={{ display: "flex", gap: "5px" }}>
                <div style={{ color: "#FFFFFF", fontSize: "18px" }}>
                  {payload[0].payload.create}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { paginationData, transactionList, tradesData } = this.state;
    const { successfulTotal, openTotal } = this.calculateTradeTotals();

    const successfulTrade = this.handleCondition(tradesData.length, tradesData.find(
      (item: { attributes: { trade_type: string } }) =>
        item.attributes.trade_type === "Successful Trade"
    ), "");

    const openTrade = this.handleCondition(tradesData.length, tradesData.find(
      (item: { attributes: { trade_type: string } }) =>
        item.attributes.trade_type === "Open Trade"
    ), "");
    return (
      <div style={{ display: "flex", width: "fit-content" }}>
        <Box style={webStyle.navigationMenu}>
          <NavigationMenu
            id=""
            navigation={this.props.navigation}
            i18n={this.props.i18n}
          ></NavigationMenu>
        </Box>
        <this.MainWrapper>
          <Box className="container">
          <div style={{padding:"0px 0px 10px 35px"}} onClick={this.listDataOftrans}><img style={{cursor:"pointer"}} src={this.handleCondition(this.state.transactionList , backAr, "")}/></div>
            <Box className="dashboardBox">
              <Box className="dashBox">
                <Typography className="dashboardStyle">
                  {
                    this.handleCondition(transactionList , "Transactions", "Weekly Performances")
                  }
                </Typography>
              </Box>
              <Box className="dashboardBox2">
              {this.handleCondition(transactionList , (
                <Box className="serachBox">
                <img src={searchIcon} className="serchStyle" />
                <input
                  placeholder="Type to search"
                  className="dashboardSearch"
                />
              </Box>
              ) , "")}
                {this.handleCondition
                (this.state.searchPopUp , (
                  <Box className="serachBoxMobile">
                  <img src={searchIcon} className="serchStyle" onClick={this.handleSearchPopUp}/>
                  <input
                    placeholder="Type to search"
                    className="dashboardSearch"
                  />
                </Box>
              ) , 
              <img src={searchButton} className="mobileSearch" onClick={this.handleSearchPopUp}/>)
                }
                <img src={plusButton} className="ButtonPlus" data-test-id="handleButtonBox" onClick={this.handleButtonBox} style={{position: "relative"}}/>
                {this.handleCondition(this.state.buttonBox, (
                          <Box
                            className="profitContainerButton"
                            style={{ position: "absolute" }}
                          >
                            <Box className="popupBox2">
                            <Button
                              data-test-id="profitList0"
                              key="0"
                              className={`profitData ${
                                this.handleCondition(this.state.activeTab === 0, 'active', 'profitData')
                              }`}
                              onClick={this.withdrawPage}
                            >
                              New Withdraw Request
                            </Button>

                            <Button
                              data-test-id="profitList1"
                              key="1"
                              className={`profitData ${
                                this.handleCondition(this.state.activeTab === 1, 'active', 'profitData')
                              }`}
                              onClick={this.depositPage}
                            >
                              New Deposit Request
                            </Button>
                            </Box>
                          </Box>
                        ), "")}
                <Box className="requestButton" 
                  data-test-id={"WebnavigateToWithdraw"}
                 onClick={this.withdrawPage}>
                  <img src={PlusIcon} className="plusStyle" />
                  <button className="buttonOne">New Withdraw Request</button>
                </Box>
                <Box className="requestButton"
                data-test-id={"WebnavigateToDeposit"}
                 onClick={this.depositPage}>
                  <img src={PlusIcon} className="plusStyle" />
                  <button className="buttonOne">New Deposit Request</button>
                </Box>
              </Box>
            </Box>
            {transactionList? (
              <div>
              <Box className="transactionListBox">
                <Box className="lastBox">
                  <Box className="headingBoxT">
                    <Typography className="headingStyleNameT">Date</Typography>
                    <Typography className="headingStyleT">
                      Received Money
                    </Typography>
                    <Typography className="headingStyleT">Status</Typography>
                  </Box>
                  {this.state.weeklyData.map((item: {attributes: {status: string; created_at: string; profit_amount: string}}, index: number) => {
                    const color = this.stepColor(item.attributes.status as TStatus);
                    return (
                      <Box
                        className="itemBoxT"
                        key={index}
                        style={{
                          borderBottom:
                            this.handleCondition(item.attributes.status === "Received", "1px solid #CBD5E1" , "none")
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: "8px",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <Typography className="itemDataName">
                            {new Date(item.attributes.created_at).toLocaleDateString('en-GB', {
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: 'numeric'
                                                  })}
                          </Typography>
                        </Box>
                        <Typography className="itemData" style={{ color: `${color}` }}>
                          {item.attributes.profit_amount}
                        </Typography>
                        <Box className="itemDataStatus">
                          <Typography
                            className="itemDataStatus2"
                            style={{ color, backgroundColor: `${color}30` }}
                          >
                            {item.attributes.status}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Box className="tableMobileViewT">
                      <Box className="responsiveCardContainer">
                        {this.state.weeklyData.map(
                          (item: {attributes : {status: string; created_at: string; profit_amount: string}}, index: number) => {
                            const color = this.stepColor(
                              item.attributes.status as TStatus
                            );
                              return (
                                <Card key={index} elevation={0} className="responsiveCard">
                                  <Box
                                    className="cardContent"
                                    style={{
                                      backgroundColor:
                                       this.handleCondition( index % 2 === 0 , "#F1F5F9", "none")
                                    }}
                                  >
                                    {["Date", "Received Money", "Status"].map(
                                      (label, idx) => (
                                        <Box
                                          key={idx}
                                          sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            mb: 2,
                                          }}
                                        >
                                          <Typography className="headingStyleMobile">
                                            {label}
                                          </Typography>
                                          <Typography className="tableContentText">
                                            {this.handleCondition(label === "Date", (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <span>{new Date(item.attributes.created_at).toLocaleDateString('en-GB', {
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: 'numeric'
                                                  })}</span>
                                              </div>
                                            ), "")}
                                            {this.handleCondition(label === "Received Money" ,
                                             (item.attributes.profit_amount), "")}
                                            {this.handleCondition(label === "Status", (
                                              <Button
                                                style={{
                                                  color,
                                                  backgroundColor: `${color}30`,
                                                }}
                                                className="tableBtn"
                                              >
                                                {item.attributes.status}
                                              </Button>
                                            ), "")}
                                          </Typography>
                                        </Box>
                                      )
                                    )}
                                  </Box>
                                </Card>
                              );
                          }
                        )}
                      </Box>
                    </Box>
                    {paginationData.totalPages > 1  && (
                                                <TableRow style={{display:"flex", alignItems: "flex-end", justifyContent:"flex-end"}}>
                                                    <TableCell colSpan={5}>
                                                        <Box display="flex" justifyContent="flex-end">
                                                            <Pagination
                                                                data-test-id="pagination"
                                                                count={paginationData.totalPages}
                                                                page={paginationData.currentPage}
                                                                onChange={(event, page) => this.handlePaginatioChange(event, page)}
                                                                color="primary"
                                                                size="small"
                                                                className="paginationRoot"
                                                            />
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )
                    }
              </div>
            ) : (
              <Box>
                <Box className="reportsContainer">
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Box className="reportsBox">
                      <img src={arrow} />
                      <Typography className="reportss">Reports</Typography>
                    </Box>
                    <Box className="Expenses">
                      <Box className="statusBox">
                        <Box className="activityrightttext"
                         style={{ position: "relative" }}>
                          <Typography className="calendar" onClick={this.openCalendar}>Calendar</Typography>
                          <img
                            src={calendar}
                            alt=""
                            onClick={this.openCalendar}
                          />
                          <CustomDatePicker>
                           {this.state.calendarOpen && (
                            <div className="reactCalendarBox"  style={{position:"absolute"}}>
                            <div className="calBox">
                            <Calendar
                              selectRange
                              onChange={this.handleDateChange}
                              data-testID="calendar"
                              value={this.state.tempDate || this.state.selectedDate}
                            />
                            <div className="action-button-div">
                              <Button 
                                className="action-btn cancel-btn" 
                                onClick={this.handleCalendarCancelBtn}
                                data-testID="cancel-btn"
                              >
                              Cancel
                            </Button>
                            <Button 
                              data-testID="save-btn"
                              className={this.handleCondition(this.state.tempDate, `action-btn save-btn active`, `action-btn save-btn`)} 
                              onClick={this.handleCalendarSaveBtn}
                            >
                              Apply
                            </Button>
                          </div>
                        </div>
                        </div>
                      )}
                        </CustomDatePicker>
                        </Box>
                        <Box className="activityrightttext"
                       onClick={this.downloadReport}
                        >
                          <img src={downloadIcon} alt="" />
                          <Typography className="downloadReport">
                            Download Report
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                        <div className="graphContainer">
                          <div style={{ minWidth: '1000px' }}>
                            <AreaChart
                              width={1400}
                              height={400}
                              data={this.state.weeklyGraphData}
                              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                            >
                              <defs>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                  <stop offset="5%" stopColor="#1B4FE4" stopOpacity={0.8} />
                                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                                </linearGradient>
                              </defs>
                              <XAxis 
                              dataKey="createdDate" 
                              style={{fontSize: "18px", color:"#94A3B8",  fontFamily:"DIN Next LT Arabic Regular"}}
                              interval={0}
                              tickCount={5} 
                              padding={{ right: 20 }} 
                              />
                              <YAxis />
                              <Tooltip content={<this.CustomTooltip tooltipData={this.state.weeklyGraphData} />} />
                              <Area type="monotone" dataKey="amountt" strokeWidth={2} stroke="#1B4FE4" fill="url(#colorUv)" />
                            </AreaChart>
                          </div>
                        </div>
                </Box>
                <Box className="activityTable">
                  <Box className="leftActivity">
                    <Box className="activityBox">
                      <Typography className="activityTextRating">
                        Performance Rating
                      </Typography>
                      <Box
                        data-test-id="handleWeekOption"
                        className="activityrightttext"
                        onClick={this.handleWeekOption}
                        style={{ position: "relative" }}
                      >
                        <Typography
                          className="calendarWeek"
                        >
                          This Week
                        </Typography>
                        <img src={drop} alt="" />
                        {this.handleCondition(this.state.weekOption, (
                          <Box
                            className="profitContainer"
                            style={{ position: "absolute" }}
                          >
                            <Box className="popupBox">
                              {this.state.profitList.map((item, index) => (
                                <Button
                                  data-test-id={`profitList${index}`}
                                  key={index}
                                  className={`profitData ${
                                    this.handleCondition(this.state.activeTab === index
                                      , "active"
                                      , "profitData")
                                  }`}
                                  onClick={() => this.handleActiveTab(index)}
                                >
                                  {item.label}
                                </Button>
                              ))}
                            </Box>
                          </Box>
                        ), "")}
                      </Box>
                    </Box>
                    <Box
                      className="ratingBox"
                    >
                      <Box style={{display:"flex", alignItems:"center", justifyContent: "center",
                            borderRadius: "50%",
                            width: "100px",  
                            height: "100px", backgroundColor:"#F1F5F9"}}>
                        <PieChart width={100} height={100}>
                          <Pie
                            data={
                              this.handleCondition(this.state.tradesData.length > 0
                                , this.state.data22
                                , [{ name: "No Data", value: 1, fill: "#94A3B8" }])
                            }
                            cx={43}
                            cy={43}
                            innerRadius={30}
                            outerRadius={40}
                            fill="#8884d8"
                            paddingAngle={0}
                            dataKey="value"
                          >
                             <Label
                              value={this.state.tradeSum.trade_sum}
                              position="center"
                              fill="#000"
                              style={{ fontSize: '16px',color:"#1B4FE4", fontFamily:"DIN Next LT Arabic Regular" }}
                            />
                          </Pie>
                        </PieChart>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          width: "100%",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          {this.handleCondition(
                            tradesData.length === 0, (
                              <Typography
                                style={{
                                  color: "#64748B",
                                  fontSize: "16px",
                                  fontFamily: "DIN Next LT Arabic Regular",
                                  textAlign: "center",
                                  padding: "16px",
                                }}
                              >
                                No data found
                              </Typography>
                            ), (
                              <>
                                {this.handleCondition(successfulTrade, (
                                  <Box
                                    style={{
                                      display: "flex",
                                      gap: "16px",
                                      padding: "8px 12px",
                                      border: "1px solid #CBD5E1",
                                      borderRadius: "8px",
                                      marginBottom: "8px",
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        fontSize: "16px",
                                        fontFamily: "DIN Next LT Arabic Regular",
                                        color: "green",
                                      }}
                                    >
                                      {successfulTotal}
                                    </Typography>
                                    <Box
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        width: "100%",
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          color: "#64748B",
                                          fontSize: "16px",
                                          fontFamily: "DIN Next LT Arabic Regular",
                                        }}
                                      >
                                        {successfulTrade?.attributes?.trade_type}
                                      </Typography>
                                      <Typography
                                        style={{
                                          width: "10px",
                                          height: "10px",
                                          backgroundColor: "green",
                                          borderRadius: "50%",
                                        }}
                                      ></Typography>
                                    </Box>
                                  </Box>
                                ), "")}
                                {this.handleCondition(openTrade, (
                                  <Box
                                    style={{
                                      display: "flex",
                                      gap: "16px",
                                      padding: "8px 12px",
                                      border: "1px solid #CBD5E1",
                                      borderRadius: "8px",
                                      marginBottom: "8px",
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        fontSize: "16px",
                                        fontFamily: "DIN Next LT Arabic Regular",
                                        color: "red",
                                      }}
                                    >
                                      {openTotal}
                                    </Typography>
                                    <Box
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        width: "100%",
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          color: "#64748B",
                                          fontSize: "16px",
                                          fontFamily: "DIN Next LT Arabic Regular",
                                        }}
                                      >
                                        {openTrade?.attributes?.trade_type}
                                      </Typography>
                                      <Typography
                                        style={{
                                          width: "10px",
                                          height: "10px",
                                          backgroundColor: "#EF4444",
                                          borderRadius: "50%",
                                        }}
                                      ></Typography>
                                    </Box>
                                  </Box>
                                ), "")}
                              </>
                            )
                          )}
                        </div>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="activityTable">
                  <Box className="leftActivity">
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        width: "100%",
                        gap: "4px",
                        flexDirection: "column",
                      }}
                    >
                      <Box className="activityBox">
                        <Typography className="activityText">
                        Account update 
                        </Typography>
                        <Typography className="activityTextSort">
                          Recent Transactions
                        </Typography>

                        <Box
                          className="activityrightttext"
                        >
                          <Typography className="calendar" onClick={this.handleTransactionList}>See All</Typography>
                          <img
                            src={rightArrow}
                            alt=""
                            className="sortDropIcon2"
                            onClick={this.handleTransactionList}
                          />
                        </Box>
                      </Box>

                      <Typography className="mobileMessage">
                        Check out and sort all transactions and investments
                      </Typography>
                    </Box>
                    <Box className="lastBox">
                      <Box className="headingBox">
                        <Typography className="headingStyleName">
                          Date
                        </Typography>
                        <Typography className="headingStyle">
                          Received Money
                        </Typography>
                        <Typography className="headingStyle">Status</Typography>
                      </Box>
                      {this.state.weeklyData.map((item: {attributes: {status: string; profit_amount: string; created_at: string}}, index: number) => {
                        const color = this.stepColor(item.attributes.status as TStatus);
                        if(index === 0 || index === 1) {
                          return (
                            <Box
                              className="itemBox"
                              key={index}
                              style={{borderBottom: this.handleCondition(item.attributes.status === "Received"
                                  , "1px solid #CBD5E1"
                                  , "none")
                              }}>
                              <Box style={{ display: "flex", justifyContent: "flex-start", gap: "8px", width: "100%", alignItems: "center" }}
                              >
                                <Typography className="itemDataName"> {new Date(item.attributes.created_at).toLocaleDateString('en-GB', {
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: 'numeric'
                                                  })} </Typography>
                              </Box>
                              <Typography className="itemData" style={{color : `${color}`}}> {item.attributes.profit_amount} </Typography>
                              <Box className="itemDataStatus">
                                <Typography
                                  className="itemDataStatus2" style={{ color, backgroundColor: `${color}30` }}
                                >{item.attributes.status}
                                </Typography>
                              </Box>
                            </Box>
                          );
                        }
                      })}
                    </Box>
                    <Box className="tableMobileView">
                      <Box className="responsiveCardContainer">
                        {this.state.weeklyData.map(
                          (item: {attributes: {status: string; created_at: string; profit_amount: string}}, index: number) => {
                            const color = this.stepColor(
                              item.attributes.status as TStatus
                            );
                            if(index === 0 || index === 1){
                              return (
                                <Card key={index} elevation={0} className="responsiveCard">
                                  <Box className="cardContent"
                                    style={{
                                      backgroundColor: this.handleCondition(index % 2 === 0, "#F1F5F9" , "none")
                                    }}
                                  >
                                    {["Date", "Received Money", "Status"].map(
                                      (label, idx) => (
                                        <Box
                                          key={idx}
                                          sx={{
                                            display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2,
                                          }}
                                        >
                                          <Typography className="headingStyleMobile">
                                            {label}</Typography>
                                          <Typography className="tableContentText">
                                            {label === "Date" && (
                                              <div
                                                style={{
                                                  display: "flex",alignItems: "center"
                                                }}
                                              >
                                                <span>{new Date(item.attributes.created_at).toLocaleDateString('en-GB', {
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: 'numeric'
                                                  })}</span>
                                              </div>
                                            )}
                                            {label === "Received Money" && item.attributes.profit_amount}
                                            {label === "Status" && (
                                              <Button
                                                style={{ color, backgroundColor: `${color}30` }}
                                                className="tableBtn"
                                              > {item.attributes.status}
                                              </Button>
                                            )}
                                          </Typography>
                                        </Box>
                                      )
                                    )}
                                  </Box>
                                </Card>
                              );
                            }
                          }
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </this.MainWrapper>
      </div>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  navigationMenu: {
    zIndex: 100,
    height: "fit-content",
    width: "fit-content",
  },
  notificationTab: {
    width: "492px",
  },
  notificationHeader: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #DDE0E0",
    padding: "16px 24px 16px 24px",
  },

  headerText: {
    fontWeight: "400",
    fontSize: "16px",
  },

  headerTextMarkAsRead: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#1B4FE4",
    cursor: "pointer",
  },

  notificationContainer: {
    height: "120px",
    backgroundColor: "#EFF6FF",
    display: "flex",
    alignItems: "center",
    padding: "18px 40px 18px 40px",
    gap: "24px",
    borderBottom: "1px solid #CBD5E1",
  },

  notificationContent: {
    flex: "1",
    width: "185px",
    height: "auto",
    fontSize: "14px",
    fontWeight: "400",
    color: "#0F172A",
  },
  timerContent: {
    fontWeight: "400",
    fontSize: "12px",
    color: "#64748B",
  },
  downloadBtn: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#1B4FE4",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    cursor: "pointer",
  },
  allNotificationContainer: {
    height: "56px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  allNotifications: {
    color: "#1B4FE4",
    fontSize: "16px",
    fontWeight: "400",
    cursor: "pointer",
  },
  optionMenu: {
    height: "96px",
    width: "178px",
    border: "1px solid #F5F5F5",
    boxShadow: "none",
    borderRadius: "8px",
    background: "#FFFFFF",
  },
  optionMarkAsRead: {
    display: "flex",
    height: "48px",
    alignItems: "center",
    gap: "8px",
    paddingLeft: "16px",
    fontWeight: "400",
    fontSize: "16px",
    color: "#F8FAFC",
    backgroundColor: "#1B4FE4",
  },
  optionDelete: {
    display: "flex",
    height: "48px",
    alignItems: "center",
    gap: "8px",
    paddingLeft: "16px",
    fontWeight: "400",
    fontSize: "16px",
  },
};

const CustomDatePicker = styled(Box)({
  borderRadius: '8px',
  position: 'relative' as 'relative',
  boxSizing: "border-box",
  "& .MuiInput-underline::after, .MuiInput-underline::before": {
    display: 'none'
  },
  "& .reactCalendarBox":{
    [theme.breakpoints.down(400)]: {
      left:50
     },
    width: "100%",
    top: 25,
    zIndex: 30,
    display: "flex",
    justifyContent: "center" as "center",
    flexDirection:"column" as "column",
    alignItems: "flex-end",
    right: 0,
    boxShadow: "0px 2px 4px 0px #00000026",
  },
  "& .react-calendar": {
    [theme.breakpoints.down(450)]: {
      width:"275px"
    },
    width:"330px",
    border: "none",
    fontFamily: "DIN Next LT Arabic Regular",
  },
  "& .react-calendar__navigation button.react-calendar__navigation__label": {
    fontSize: '14px',
    fontFamily: "DIN Next LT Arabic Regular",
    fontWeight: 700,
    color: "#0F172A",
  },
  "& .react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button":{
    display: "none",
  },
  "& .react-calendar__navigation button": {
    fontSize: '14px',
    fontFamily: "DIN Next LT Arabic Regular",
    fontWeight: 700,
    color: "#94A3B8", 
  },
  "& .react-calendar__tile.react-calendar__tile--now": {
    background: 'none',
    color: '#0F172A',
  },
  "& .react-calendar__month-view__weekdays__weekday": {
    textTransform: 'capitalize',
    fontSize: '14px',
    color: '#64748B',
    fontWeight: 400,
    textDecoration: 'none',
  },
  "& .react-calendar__month-view__weekdays__weekday abbr": {
    textDecoration: 'none',
  },
  "& .react-calendar__tile": {
    fontSize: '14px',
    fontWeight: 400,
    color: '#0F172A',
    width: '50px',
    height: '50px',
  },
  "& .react-calendar__tile.react-calendar__tile--range": {
    background: '#F1F5F9',
    color: '#044352',
    borderRadius: '0px',
  },
  "& .react-calendar__tile.react-calendar__tile--rangeStart": {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
    background: '#F1F5F9', 
    color: '#044352',
  },
  "& .react-calendar__tile.react-calendar__tile--rangeEnd": {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    background: '#F1F5F9',
    color: '#044352',
  },
  "& .react-calendar__tile.react-calendar__tile--active, .react-calendar__tile.react-calendar button:enabled": {
    background: '#1B4FE4',
    color: '#F8FAFC',
    fontSize: '14px',
    fontWeight: 700,
  },
  "& .react-calendar__tile.react-calendar__tile--active:hover, .react-calendar__tile.react-calendar button:enabled:hover": {
    background: '#F1F5F9',
    color: '#044352',
    pointerEvents: "none",
},
  "& .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth": {
    color: '#94A3B8'
  },
  "& .action-button-div": {
    [theme.breakpoints.down(450)]: {
      width:"calc(275px - 30px)"
    },
    width:"calc(330px - 30px)",
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 15px',
    gap: '20px',
    backgroundColor: "#FFFFFF"
  },
  "& .action-button-div button": {
    width: '140px',
    height: '50px',
    borderRadius: '7.21px',
    fontFamily: "DIN Next LT Arabic Regular",
    textTransform: 'capitalize',
    fontSize: '14.42px',
  },
  "& .action-button-div .cancel-btn": {
    background: '#F1F5F9',
    color: '#94A3B8',
    boxShadow: "0px 3.6px 3.6px 0px #00000040"
  },
  "& .action-button-div .save-btn.active": {
    background: '#1B4FE4',
    color: '#FFFFFF'
  },
  "& .action-button-div .save-btn": {
    background: '#F1F5F9',
    color: '#94A3B8',
    boxShadow: "0px 3.6px 3.6px 0px #00000040"
  },
});

export const WeeklyPerformanceWeb = withTranslation()(WeeklyPerformance)
// Customizable Area End
